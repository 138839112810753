<template>
	<div class="index">
		<div class="chat-list">
			<div class="nav-title">上传音乐</div>
			<div class="chat-list-user">
				<div v-for="(item,index) in chatList" :key="index" @click="lookChatRecords(index)"
					:class="current == index?'active-background':'user-info'">
					<div class="expression-steps">
						<p>{{item.name}}</p>
						<p v-if="item.num">{{item.num}}</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 上传新表情 -->
		<upload-new-music v-if="current == 0" @musicUpdateTotal="musicUpdateTotal"></upload-new-music>
		<!-- 上传记录 -->
		<upload-music-record v-if="current == 1" @musicUpdateTotal="musicUpdateTotal"></upload-music-record>
		<!-- 结算管理 -->
		<management ref="management" v-if="current == 2"></management>
	</div>
</template>

<script>
	import upload from "@/api/apiModel/upload"
	import uploadMusicRecord from "./uploadMusicRecord"
	import uploadNewMusic from "./uploadNewMusic"
	import management from "./management/index.vue"; //结算管理
	export default {
		name: 'uploadExpression',
		components: {
			uploadMusicRecord,
			uploadNewMusic,
			management
		},
		data() {
			return {
				current: 0,
				isShow: true,
				chatList: [{
						name: '上传新音乐'
					},
					{
						name: '上传记录',
						num: null
					},
					{
						name: '结算管理',
						num: null
					},
				],
				total: null,
			}
		},
		created() {
			// 获取上传记录数
			upload.getUploadMusicList().then(res => {
				this.total = res.result.total
				if (this.total != 0) {
					this.chatList[1].num = this.total
				}
			})
		},
		methods: {
			// 上传音乐记录数量
			musicUpdateTotal(total) {
				this.chatList[1].num = total
				this.$store.dispatch('changeNum', "上传音乐")
			},
			// 查看对应用户聊天记录
			lookChatRecords(index) {
				this.current = index
				this.isShow = false
			},
		}
	};
</script>

<style scoped>
	.index {
		display: flex;
		flex: 1;
	}

	.chat-list {
		width: 253px;
		user-select: none;
		cursor: pointer;
		height: 100%;
		background: #ffffff;
	}

	.chat-list .chat-list-user {
		height: 100%;
		/* height: 473px; */
		overflow-x: hidden;
		overflow-y: auto;
	}

	.nav-title {
		height: 60px;
		line-height: 60px;
		font-size: 18px;
		letter-spacing: 2px;
		padding: 10px;
		display: flex;
		align-items: center;
		background: #f5f7fa;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.chat-list-user .user-info {
		height: 57px;
		display: flex;
		align-items: center;
		padding: 0 12px;
		border-bottom: 1px solid #dcdfe6;
		box-sizing: border-box;
	}

	.user-info:hover {
		background: #f5f7fa;
	}

	.expression-steps {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 16px;
		color: #313233;
	}

	.expression-steps p:nth-child(1) {
		margin-right: 8px;
	}

	.expression-steps p:nth-child(2) {
		font-size: 14px;
		color: #7b7d80;
	}

	.active-background {
		height: 57px;
		display: flex;
		align-items: center;
		background: #f5f7fa;
		padding: 0 12px;
		border-bottom: 1px solid #dcdfe6;
		box-sizing: border-box;
	}
</style>
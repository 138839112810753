<template>
	<div class="chat-window">
		<div class="chat-title">
			<p>结算管理</p>
		</div>
		<div style="display: flex;">
			<div style="margin: 22px 0 0 10px;">
				<el-form :inline="true">
					<el-form-item label="年份">
						<el-date-picker v-model="value3" type="year" placeholder="选择年" format="yyyy" value-format="yyyy"
							@change='yearChange'>
						</el-date-picker>
					</el-form-item>
					<el-form-item label="月份">
						<el-date-picker v-model="value2" type="month" placeholder="选择月" format="MM" value-format='MM'
							@change="monthChange">
						</el-date-picker>
					</el-form-item>
				</el-form>
			</div>
			<div slot="action" style="margin: auto 0;">
				<el-button size="small" type="primary"
					style="margin: 0 0 0 10px;background-color: rgba(40, 168, 207, 1);" @click="getList">搜索</el-button>
				<el-button size="small" @click="resetTable">重置</el-button>
			</div>
		</div>
		<div style="margin: 0 10px;">
			<el-table :data="music_list" style="width: 100%" border>
				<el-table-column label="上店名称" align="center" prop="title">
				</el-table-column>
				<el-table-column label="价格" align="center" prop="price">
					<template slot-scope="scope" v-if="scope.row.price == 0">免费</template>
					<template slot-scope="scope" v-else>{{scope.row.price}}</template>
				</el-table-column>
				<el-table-column label="本月收入" align="center" prop="incomeThisMonth">
				</el-table-column>
				<el-table-column label="分成比例" align="center" prop="shareRatio">
				</el-table-column>
				<el-table-column label="月分成额" align="center" prop="monthlyShare">
				</el-table-column>
				<el-table-column label="结算时间" align="center" prop="settlementTime">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import upload from "@/api/apiModel/upload"
	export default {
		data() {
			return {
				tableData: [],
				value2: '',
				value3: '',
				getKey: '',
				form: {
					month: '',
					year: ""
				},
				music_list: []
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				upload.musicInfo(this.form).then(res => {
					// this.music_list = res.reslut
				})
			},
			resetTable() {
				this.form = {}
				this.getList()
			},
			yearChange(e) {
				this.form.year = e
			},
			monthChange(e) {
				this.form.month = e
			}
		}
	}
</script>

<style lang="less" scoped>
	.chat-window {
		min-width: 506px;
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		background: #ffffff;
		border-left: 1px solid #dcdfe6;
	}

	.chat-window .chat-title {
		display: flex;
		align-items: center;
		height: 60px;
		font-size: 18px;
		color: #313233;
		letter-spacing: 2px;
		padding: 0 20px;
		background: #ffffff;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.chat-window .chat-title p:nth-child(2) {
		font-size: 18px;
		color: #7b7d80;
		margin-left: 8px;
	}
</style>
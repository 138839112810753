<template>
	<div class="chat-window">
		<div class="chat-title">
			<p>上传记录</p>
			<p>{{ total }}</p>
		</div>
		<div class="list-content">
			<el-table :data="tableData" style="width: 100%" border>
				<el-table-column label="文件名称" align="center" width="250px" prop="musicName">
					<template slot-scope="scope">{{ scope.row.musicName }}</template>
				</el-table-column>
				<el-table-column label="时长" align="center" prop="duration">
					<template slot-scope="scope">{{ scope.row.duration }}</template>
				</el-table-column>
				<el-table-column label="大小" align="center" prop="fileSize">
					<template slot-scope="scope">{{
                        scope.row.fileSize | sizeFilter
                        }}
					</template>
				</el-table-column>
				<el-table-column label="状态" align="center">
					<template slot-scope="scope"><span style="color: #FF6632;" @click="deleteMusic(scope.row)">删除</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import upload from "@/api/apiModel/upload";

	export default {
		// 上传的音乐记录
		name: "uploadMusicRecord",
		data() {
			return {
				tableData: [],
				total: null,
			};
		},
		filters: {
			sizeFilter(limit) {
				var size = "";
				if (limit < 0.1 * 1024) {
					//小于0.1KB，则转化成B
					size = limit.toFixed(2) + "B";
				} else if (limit < 0.1 * 1024 * 1024) {
					//小于0.1MB，则转化成KB
					size = (limit / 1024).toFixed(2) + "KB";
				} else if (limit < 0.1 * 1024 * 1024 * 1024) {
					//小于0.1GB，则转化成MB
					size = (limit / (1024 * 1024)).toFixed(2) + "MB";
				} else {
					//其他转化成GB
					size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
				}
				var sizeStr = size + ""; //转成字符串
				var index = sizeStr.indexOf("."); //获取小数点处的索引
				var dou = sizeStr.substr(index + 1, 2); //获取小数点后两位的值
				if (dou == "00") {
					//判断后两位是否为00，如果是则删除00
					return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
				}
				return size;
			},
		},
		created() {
			this.getList();
		},
		methods: {
			// 获取上传音乐记录
			getList() {
				upload.getUploadMusicList({
					pageNo: 1,
					pageSize: 1000000
				}).then((res) => {
					this.tableData = res.result.records;
					this.total = res.result.total;
					this.$emit("musicUpdateTotal", res.result.total);
				});
			},
			// 删除音乐记录
			deleteMusic(item) {
				this.$confirm(`您确定要删除音乐《${item.musicName}》吗？`, '删除音乐', {
					center: true
				}).then(() => {
					upload.deleteMusic({
						musicId: item.id
					}).then(() => {
						this.getList();
					});
				});
			}
		},
	};
</script>

<style scoped>
	.chat-window {
		min-width: 506px;
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		background: #ffffff;
		border-left: 1px solid #dcdfe6;
	}

	.chat-window .chat-title {
		display: flex;
		align-items: center;
		height: 60px;
		font-size: 18px;
		color: #313233;
		letter-spacing: 2px;
		padding: 0 20px;
		background: #ffffff;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.chat-window .chat-title p:nth-child(2) {
		font-size: 18px;
		color: #7b7d80;
		margin-left: 8px;
	}

	.list-content {
		height: 540px;
		overflow-x: hidden;
		overflow-y: auto;
	}
</style>
<template>
	<div class="chat-window">
		<div class="chat-title">上传新音乐</div>
		<div class="chat-container">
			<el-form :model="model" label-position="top" size="small" class="content">
				<el-form-item label="音乐名称">
					<el-input v-model="model.musicName" class="input-style" placeholder="请填写音乐名称,长度不超过10字节" maxlength="10"></el-input>
				</el-form-item>
				<el-form-item label="歌手名称">
					<el-input v-model="model.singerName" class="input-style" placeholder="请填写歌手名称"></el-input>
				</el-form-item>
				<el-form-item label="音乐封面图片">
					<p class="remark">JPG/PNG 格式，180 ×180 像素，大小不超过500 KB</p>
					<el-upload class="upload-demo" :action="host" :show-file-list="false" :on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload" :data="ossForm" :multiple="false">
						<img v-if="imageUrl" :src="imageUrl" class="img_show">
						<img v-else src="@/assets/images/ic_shangchuan@2x.png">
					</el-upload>
				</el-form-item>
				<el-form-item label="选择作品门类">
					<div class="category-grid">
						<div class="category-item" :class="item.itemValue == model.musicType ? 'active' : ''"
							v-for="(item, index) in categoryList" :key="index" @click="model.musicType = item.itemValue">
							{{item.itemText}}
						</div>
					</div>
				</el-form-item>
				<el-form-item label="上传音乐文件">
					<el-upload :action="host" :show-file-list="false" :on-success="handleMusicSuccess"
						:before-upload="beforeMusicUpload" :data="ossForm" :multiple="false">
						<el-button round size="mini"><i class="el-icon-plus"></i>上传音乐
						</el-button>
					</el-upload>
					<div class="table-list">
						<el-table :data="tableData" :header-cell-style="{background:'#F5F7FA', color:'#7B7D80'}" border
							style="width: calc(100% - 20px);">
							<el-table-column label="文件名称" align="center" width="250px" prop="name">
								<template slot-scope="scope">{{scope.row.name}}</template>
							</el-table-column>
							<el-table-column label="时长" align="center" prop="time">
								<template slot-scope="scope">{{scope.row.time | timeFomatting}}</template>
							</el-table-column>
							<el-table-column label="大小" align="center" prop="size">
								<template slot-scope="scope">{{scope.row.size | sizeFormatting}}</template>
							</el-table-column>
						</el-table>
					</div>
				</el-form-item>
				<el-form-item label="上传歌词文件">
					<div class="music-row">
						<el-upload :action="host" :show-file-list="false" :on-success="handleMusicWordSuccess"
							:before-upload="beforeMusicWordUpload" :data="ossForm" :multiple="false">
							<el-button round size="mini"><i class="el-icon-plus"></i>上传歌词
							</el-button>
						</el-upload>
						<div class="music-word" v-if="musicWordFileName">
							<img src="@/assets/images/ic_music.png">
							<label>{{musicWordFileName}}</label>
							<span @click="cancelMusicWordFile">取消</span>
						</div>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="submit">
			<div class="tip">说明：作品发布到手机端音乐家空间</div>
			<el-button type="primary" round size="small" style="background:#4db4d4;" @click="handleSubmit">
				确定
			</el-button>
		</div>
	</div>
</template>

<script>
	import upload from "@/api/apiModel/upload"
	import SingleUpload from '@/components/Upload/singleUpload'

	const defaultModel = {
		duration: null,
		fileSize: null,
		musicFileUrl: null,
		musicName: null,
		singerName: null,
		surfacePlotUrl: null,
		musicType: null,
		lyricFileUrl: null,
		lyricFileSize: null,
		lyricName: null
	}
	export default {
		// 上传新音乐
		name: 'uploadNewMusic',
		components: {
			SingleUpload
		},
		data() {
			return {
				dialogVisible: false,
				model: Object.assign({}, defaultModel),
				imageUrl: "",
				ossForm: {}, // oss配置
				host: "", // 上传地址
				tableData: [{
					name: '--',
					time: '--',
					size: '--'
				}],
				categoryList: [],
				musicWordFileName: ""
			}
		},
		filters: {
			//时间格式化 分钟内就显示多少秒 分钟外就显示几分几秒
			timeFomatting(val) {
				if (val) {
					if (val == '--') {
						return '--'
					} else {
						//秒转为分钟
						if (val < 60) {
							return val + '秒'
						} else {
							let min = Math.floor(val / 60)
							let sec = val % 60
							if (sec < 10) {
								sec = '0' + sec
							}
							return min + '分' + sec + '秒'
						}
					}
				} else {
					return '--'
				}
			},
			//文件大小格式化
			sizeFormatting(val) {
				if (val) {
					if (val == '--') {
						return '--'
					} else {
						let size = val / 1024
						if (size < 1024) {
							return size.toFixed(2) + 'KB'
						} else {
							let size1 = size / 1024
							return size1.toFixed(2) + 'MB'
						}
					}
				} else {
					return '--'
				}
			}
		},
		mounted() {
			this.$api.filmsFilmsInfo.dictList('music_type').then(res => {
				this.categoryList = res.result || []
			})
		},
		methods: {
			// 上传图片成功后
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw)
				this.model.surfacePlotUrl = res.result.filename
			},
			// 上传图片前
			beforeAvatarUpload(file) {
				// 限制是png,jpg和500kb
				const isPng = file.type === "image/png";
				const isJpg = file.type === "image/jpeg";
				const isSize = (file.size / 1024 / 1024 / 1024) * 1000000 < 500;
				if (!isPng && !isJpg) {
					this.$message.error("上传图片只能是 jpg或者png 格式!");
					return false
				}
				if (!isSize) {
					this.$message.error("上传图片大小不能超过 500kb!");
					return false
				}
				// promise 构造oss参数
				return new Promise((resolve, reject) => {
					upload.policy().then((res) => {
						this.host = res.result.host
						this.ossForm.OSSAccessKeyId = res.result.accessKeyId
						this.ossForm.callback = res.result.callback
						this.ossForm.key = res.result.dir + "/" + file.uid + file.name
						this.ossForm.policy = res.result.policy
						this.ossForm.signature = res.result.signature
						this.ossForm.success_action_status = 200
						resolve(true)
					}).catch((err) => {
						console.log(err)
						reject(false)
					})
				})
			},
			// 上传音乐成功
			handleMusicSuccess(res, file) {
				this.model.musicFileUrl = res.result.filename
				this.model.fileSize = file.size
				var url = URL.createObjectURL(file.raw)
				var audioElement = new Audio(url)
				let duration = null
				this.tableData[0].time = 0
				// 扩展 onloadedmetadata 音频加载完执行，onload图片加载完执行
				audioElement.onloadedmetadata = () => {
					// 时长为秒，取整
					duration = parseInt(audioElement.duration)
					this.model.duration = duration
					this.tableData[0].time = duration
				}
				// console.log(this.tableData[0].time)
				this.tableData[0].name = file.name
				this.tableData[0].size = file.size
				this.$forceUpdate()
			},
			// 上传音乐前
			beforeMusicUpload(file) {
				// 限制上传mp3格式
				const isPng = file.type === "audio/mpeg"
				if (!isPng) {
					this.$message.error("上传音乐文件只能是 mp3 格式!")
					return false
				}
				// promise 构造oss参数
				return new Promise((resolve, reject) => {
					upload.policy().then((res) => {
						this.host = res.result.host
						this.ossForm.OSSAccessKeyId = res.result.accessKeyId
						this.ossForm.callback = res.result.callback
						this.ossForm.key = res.result.dir + "/" + file.uid + file.name
						this.ossForm.policy = res.result.policy
						this.ossForm.signature = res.result.signature
						this.ossForm.success_action_status = 200
						resolve(true)
					}).catch((err) => {
						console.log(err)
						reject(false)
					})
				})
			},
			// 上传歌词成功
			handleMusicWordSuccess(res, file) {
				this.musicWordFileName = file.name
				this.model.lyricName = file.name
				this.model.lyricFileSize = file.size
				this.model.lyricFileUrl = res.result.filename
			},
			// 上传音乐前
			beforeMusicWordUpload(file) {
				// promise 构造oss参数
				return new Promise((resolve, reject) => {
					upload.policy().then((res) => {
						this.host = res.result.host
						this.ossForm.OSSAccessKeyId = res.result.accessKeyId
						this.ossForm.callback = res.result.callback
						this.ossForm.key = res.result.dir + "/" + file.uid + file.name
						this.ossForm.policy = res.result.policy
						this.ossForm.signature = res.result.signature
						this.ossForm.success_action_status = 200
						resolve(true)
					}).catch((err) => {
						console.log(err)
						reject(false)
					})
				})
			},
			// 取消上传的歌词文件
			cancelMusicWordFile() {
				this.musicWordFileName = ''
				this.model.musicWordFileUrl = null
			},
			// 确定
			handleSubmit() {
				if (!this.model.musicName) {
					this.$message.error('请填写音乐名称')
					return
				}
				if (!this.model.singerName) {
					this.$message.error('请填写歌手名称')
					return
				}
				if (!this.model.surfacePlotUrl) {
					this.$message.error('请选择音乐封面')
					return
				}
				if (!this.model.musicType) {
					this.$message.error('请选择作品门类')
					return
				}
				if (!this.model.musicFileUrl) {
					this.$message.error('请上传音乐')
					return
				}
				upload.uploadMusic(this.model).then(res => {
					this.model = Object.assign({}, defaultModel)
					this.imageUrl = ""
					this.musicWordFileName = ""
					this.tableData = [{
						name: null,
						time: null,
						size: null
					}]
					this.$message.success('上传音乐成功')
					upload.getUploadMusicList().then(res => {
						this.$emit("musicUpdateTotal", res.result.total)
					})
				})
			}
		}
	}
</script>

<style scoped>
	.chat-window {
		min-width: 506px;
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		background: #ffffff;
		border-left: 1px solid #dcdfe6;
		position: relative;
	}

	.chat-window .chat-title {
		display: flex;
		align-items: center;
		height: 60px;
		font-size: 18px;
		color: #313233;
		letter-spacing: 2px;
		padding: 0 20px;
		background: #ffffff;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.chat-window .chat-container {
		height: auto;
		padding-top: 20px;
		padding-bottom: 88px;
		box-sizing: content-box;
		overflow-y: auto;
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
		position: relative;
	}

	.category-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding-bottom: 20px;
	}

	.category-item {
		display: inline-block;
		padding: 9px;
		min-width: 46px;
		line-height: 1.2;
		text-align: center;
		margin: 10px 10px 0 0;
		color: #313233;
		font-size: 14px;
		background: #f5f7fa;
		border-radius: 5px;
	}

	.category-item.active {
		color: #4db4d4;
	}

	.chat-container .dialog {
		position: absolute;
	}

	.content .el-form-item {
		border-bottom: 1px solid #dcdfe6;
		padding: 0 20px;
	}

	.el-form--label-top>>>.el-form-item__label {
		padding: 0;
	}

	.content .el-form-item:last-child {
		border-bottom: 0;
	}

	.content .remark {
		font-size: 12px;
		color: #abaeb3;
		margin-top: -10px;
	}

	.el-icon-plus {
		font-size: 13px;
		color: #28a8cf;
		margin-right: 4px;
	}

	.submit {
		height: 68px;
		display: flex;
		align-items: center;
		width: 100%;
		border-top: 1px solid #dcdfe6;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: #ffffff;
		box-sizing: content-box;
		justify-content: space-between;
	}

	.submit .tip {
		padding-left: 20px;
		color: #7B7D80;
		font-size: 12px;
	}

	.submit button {
		width: 70px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 20px;
	}

	.input-style /deep/ input {
		border: none !important;
		padding-left: 0;
	}

	.table-list {
		padding: 20px 0;
	}

	.upload-demo /deep/ .el-upload {
		width: 100%;
		height: 60px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		border: none;
	}

	.upload-demo .img_show {
		width: 100px;
		height: 100%;
		max-height: 60px;
		object-fit: contain;
	}

	.upload-demo img {
		width: 32px;
		height: 32px;
		margin-bottom: 10px;
	}

	.upload-demo .el-upload__text {
		font-size: 8px;
		color: #7a7c80;
	}

	.music-row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.music-word {
		margin-left: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.music-word img {
		width: 16px;
		height: 16px;
	}

	.music-word label {
		margin: 0 5px;
	}

	.music-word span {
		padding: 0 15px;
		color: #FF6632;
	}
</style>